<template>
    <div :class="configData && Object.keys(configData).length > 0 && (!configData.is_proxy_site || (configData.is_proxy_site && configData.proxy_info.member_site_id)!=87) ? 'navbar': 'half-navbar'">
        <div class="top-banner" @click="topBannerClick" 
        v-if="configData && Object.keys(configData).length > 0 && (!configData.is_proxy_site || (configData.is_proxy_site && configData.proxy_info.member_site_id!=87))" v-cloak>
            <img :src="require('@/index/assets/top_banner.png')" alt="" />
        </div>
        <div class="flex flex-between">
            <div class="flex align-center nav-top-left">
                <div class="img fc-fff flex" v-if="configData.proxy_info">
                    <img :src="configData.proxy_info && configData.proxy_info.site_logo &&  configData.proxy_info.site_logo.indexOf('/') > -1 ? (configData.proxy_info.site_logo.indexOf('//') > -1 ? configData.proxy_info.site_logo : 'http://' + configData.static_url + '/' + configData.proxy_info.site_logo) : require('../../assets/img/logo.png')" />
                </div>
                <div class="marginL-20 navbar-list">
                    <template v-for="item in getHeaderMenu(headerMenu)" v-if="showTopNav">
                        <!-- API开放平台单独处理 -->
                        <span v-if="item.name === 'API开放平台'" :key="item.name">
                            <a v-if="configData.proxy_info && configData.proxy_info.site_doc_host" :href="configData.proxy_info.site_doc_host.indexOf('//') > -1 ? configData.proxy_info.site_doc_host : `//${configData.proxy_info.site_doc_host}`">API开放平台</a>
                        </span>
                        <!--打单软件 必须是会员且满足一定条件才展示出来-->
                        <span v-else-if="item.name === '打单软件'">
                            <a v-if="!!+userData.is_authorize" href="javascript:;" @click="goWmt">打单软件</a>
                        </span>
                        <span v-else-if="item.hasTips" class="has-tips">
                            <a
                                v-if="item.url(userData).includes('://')"
                                :href="item.url(userData)"
                                target="_blank"
                                :class="{
                                    'customer-link-active': item.customerLink && item.customerLink.active($route, siderBarCate),
                                    'no-customer-link-active': item.noCustomerLinkActive && item.noCustomerLinkActive.active($route),
                                }"
                            >{{item.name}}</a>
                            <router-link
                                v-else
                                :to="item.url(userData)"
                                target="_blank"
                                :class="{
                                    'customer-link-active': item.customerLink && item.customerLink.active($route, siderBarCate),
                                    'no-customer-link-active': item.noCustomerLinkActive && item.noCustomerLinkActive.active($route),
                                }"
                            >{{item.name}}</router-link>
                            <span class="tips-content">{{item.hasTips}}</span>
                        </span>
                        <a
                            v-else-if="item.url(userData).includes('://') && (!item.onlySite || (item.onlySite && curLocation.indexOf(item.onlySite)>-1))"
                            :href="item.url(userData)"
                            target="_blank"
                            :class="{
                                'customer-link-active': item.customerLink && item.customerLink.active($route, siderBarCate),
                                'no-customer-link-active': item.noCustomerLinkActive && item.noCustomerLinkActive.active($route),
                            }"
                        >{{item.name}}</a>
                        <router-link
                            v-else
                            :to="item.url(userData)"
                            target="_blank"
                            :class="{
                                'customer-link-active': item.customerLink && item.customerLink.active($route, siderBarCate),
                                'no-customer-link-active': item.noCustomerLinkActive && item.noCustomerLinkActive.active($route),
                            }"
                        >{{item.name}}</router-link>
                    </template>
                    <a v-if="isWebSite('www.cketool.com')" target="_blank" href="https://www.duoduocm.com/">电商资源</a>
                </div>
            </div>
            <div v-if="$store.state.phone" class="flex align-center nav-top-right">
                <div class="my-money">
                    <span>点券：{{userData.surplus_total_point}}点</span>
                </div>
                <el-button type="primary" size="small" @click="openRecharge">充值</el-button>
                <div v-if="userData.is_vip && userData.active_vip_end_time" class="my-money">
                    <span>会员到期：剩余{{showtime(userData.active_vip_end_time * 1000, true)}}</span>
                </div>
                <el-button v-if="userData.is_vip && userData.active_vip_end_time" type="primary" size="small" @click="$router.push('/vip')">续费</el-button>
                <div class="name">
                    <el-dropdown class="drop" size="small" @command="handleCommand">
                        <span class="el-dropdown-link">
                            <img v-if="$store.state.userData.is_wechat" class="top-vip" :src="$store.state.userData.head_img" alt />
                            <i v-else class="el-icon-user-solid fc-theme fs-28"></i>
                            <i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <div class="fc-666 fs-14 dropdown-phone text-center padding">{{userData.phone}}</div>
                            <el-dropdown-item command="personCenter">
                                <span>个人中心</span>
                            </el-dropdown-item>
                            <el-dropdown-item command="newsCenter">
                                <span>消息中心</span>
                                <el-badge v-if="messageUnreadCount + platformUnreadCount" :value="messageUnreadCount + platformUnreadCount" />
                            </el-dropdown-item>
                            <el-dropdown-item command="logout">
                                <span>退出登录</span>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
            <div v-else class="flex align-center nav-top-right fs-14">
                <div>
                    未登录，点击
                    <span class="marginL-5 fc-theme pointer" @click="modal.login = true">登录</span>
                    <span class="fc-theme marginL-5 pointer" @click="modal.register = true">注册</span>
                </div>
            </div>
        </div>
        <div>
            <sidebar-right @showKf="modal.service = true" />
        </div>
        <login v-if="modal.login" v-model="modal.login" @forget="modal.forget = true" @wechatlogin="wechatlogin" @success="loginSuccess"></login>
        <NoBindUser v-if="modal.noBindUser" v-model="modal.noBindUser" @showLogin="modal.login = true" @showRegister="modal.register = true"></NoBindUser>
        <forget
            @success="modal.login = false, modal.forget = false"
            v-if="modal.forget"
            v-model="modal.forget"
        ></forget>
        <register @success="regSuccess" v-if="modal.register" v-model="modal.register"></register>
        <service v-if="modal.service" v-model="modal.service"></service>
        <socket ref="socket"></socket>
        <qr-code v-if="qrCodeDialog.show" v-model="qrCodeDialog.show" :ex-data="qrCodeDialog.exData" ref="qrCodeRef"></qr-code>
        <push-qr-code v-if="pushQrCodeDialog.show" v-model="pushQrCodeDialog.show" :ex-data="pushQrCodeDialog.exData" ref="pushQrCodeRef"></push-qr-code>
        <div v-if="+$store.state.configData.recharge_activity_status === 1">
            <new-bind-store v-if="newBindStoreDialog.show" v-model="newBindStoreDialog.show" :exData="newBindStoreDialog.exData"></new-bind-store>
        </div>
    </div>
</template>

<script>
import login from '../_dialog/login'
import forget from '../_dialog/forget'
import register from '../_dialog/register'
import service from '../_dialog/service'
import WeChatLogin from '../_dialog/WeChatLogin'
import NoBindUser from '../_dialog/NoBindUser'
import { mapState } from 'vuex'
import { showtime } from '../../plugins/timediff'
import Socket from '../socket.vue';
import Cookies from 'js-cookie'
import QrCode from '../_dialog/QrCode'
import PushQrCode from '../_dialog/PushQrCode'
import MyPackage from "@/index/views/buy_package/_components/MyPackage"
import NewBindStore from '@/index/components/NewBindStore/index'
import SidebarRight from "@/index/components/SidebarRight/index";

export default {
    components: {
        login,
        forget,
        register,
        service,
        WeChatLogin,
        NoBindUser,
        Socket,
        QrCode,
        PushQrCode,
        MyPackage,
        NewBindStore,
        SidebarRight,
    },
    computed: {
        ...mapState(['userData', 'configData', 'siderBarCate', "navShowList", "navShowListHasErr", 'socket', 'messageUnreadCount', 'platformUnreadCount', "headerMenu", "basicMenu"]),
    },
    data() {
        return {
            curLocation: '',
            modal: {
                login: false,
                forget: false,
                register: false,
                service: false,
                weChatLogin: false,
                noBindUser: false,
            },
            showTopNav: false,
            showService: true,
            qrCodeDialog: {
                exData: {},
                show: false,
            },
            pushQrCodeDialog: {
                exData: {},
                show: false,
            },
            newBindStoreDialog: {
                show: false,
                exData: {}
            },
        }
    },
    watch: {
        $route(route) {
            // 如果存在 sign ,则通过 sign 去请求参数
            if (route.query.sign) {
                sessionStorage.setItem('sign', route.query.sign)
                this.$router.push({
                    path: route.path
                })

            } else {
                if (sessionStorage.getItem('sign')) {
                    this.requestWeChatInfo(sessionStorage.getItem('sign'));
                }
            }
        }
    },
    methods: {
        showtime,
        handleCommand(command) {
            if (command == 'logout') {
                this.logout()
            }
            if (command == 'personCenter') {
                this.$router.push('/personCenter')
            }
            if (command == 'newsCenter') {
                this.$router.push('/newsCenter')
            }
        },
        async logout() {
            this.$store.dispatch('setLogout')

            let res = await this.getBeian() // +res.is_beian === 0
            if (+res.is_beian === 0) {
                this.$router.replace({
                    path: '/',
                })
            } else {
                this.$router.replace({
                    path: '/homepage',
                })
            }
        },
        async getBeian() {
            this.loading = true
            try {
                const res = await this.$fetch('/tool/apps/get-beian', {})

                return res.data
            } finally {
                this.loading = false
            }
        },
        openRecharge() {
            window.open('/recharge')
        },
        openReg() {
            this.modal.register = true
        },
        openLogin() {
            this.modal.login = true
        },
        wechatlogin() {
        },
        async requestWeChatInfo(sign) {
            const res = await this.$fetch(`/tool/members/wechat-result-notice?sign=${sign}`, {})

            let resultInfoText = {
                0: '未扫码',
                1: '登录成功',
                2: '未绑定用户',
                3: '个人中心发起绑定成功',
                4: '用户未同意授权',
                5: '未知错误',
            }

            if (res.code == 0) {
                let info = res.data
                const { open_id, result_type, result_info, result_error_info } = info

                // 未扫码(这里应该不会出现未扫码的情况, 因为自由扫码后会进入到这个程序, 不过为了保险起见, 如果是未扫码情况, 则直接打开微信扫码)
                if (result_type == 0) {
                    this.modal.weChatLogin = true;

                    return false;
                }

                // 登录成功
                if (result_type == 1) {
                    this.$store.dispatch('setLogin', result_info);
                    sessionStorage.removeItem('sign');
                    setTimeout(() => {
                        this.$store.dispatch('upDateUser')
                    }, 400)
                }

                // 未绑定用户
                if (result_type == 2) {
                    this.modal.noBindUser = true;
                    sessionStorage.setItem('open_id', info.open_id);
                }

                // 个人中心发起绑定成功
                if (result_type == 3) {
                    this.$store.dispatch('upDateUser');
                    sessionStorage.removeItem('sign');
                }

                // 用户未同意授权
                if (result_type == 4) {
                    this.$message.warning('用户未同意授权!');
                    sessionStorage.removeItem('sign');
                }

                // 未知错误
                if (result_type == 5) {
                    this.$message.warning(result_error_info);
                    sessionStorage.removeItem('sign');
                }
            }
        },
        closeAll() {
            Object.keys(this.modal).forEach(item => {
                this.modal[item] = false;
            })
            sessionStorage.removeItem('sign');
            sessionStorage.removeItem('open_id');
        },
        regSuccess(open_id) {
            // 打开新的弹框前, 先关闭所有弹框
            this.closeAll();
        },
        loginSuccess() {
            // TODO: 登录成功后触发socket
            // this.setSocket();
            this.$refs.socket.setSocket();

            // 登录后获取我的套餐
            this.$store.dispatch('getMyPackages')

            // 打开新的弹框前, 先关闭所有弹框
            this.closeAll();
        },
        goWmt() {
            let token = '';

            if (this.isToolMainSite()) {
                let CookieToken = Cookies.get('_vt')
                let localToken = localStorage.token

                if (CookieToken && CookieToken !== localToken) {
                    localStorage.token = CookieToken
                }
                if (CookieToken) {
                    token = CookieToken
                } else {
                    token = localStorage.token
                }
            } else {
                token = localStorage.token
            }

            if (this.configData.wmt_host) {
                window.open(`http://${this.configData.wmt_host}/wmt-vt/login-with?token=${token}`)
            } else {
                window.open(`/wmt-vt/login-with?token=${token}`)
            }

        },

        // top banner
        topBannerClick() {
            this.$router.push({
                path: "/buy-package"
            })
        },

        openBindStore() {
            // 充值页不弹框
            if (window.location.href.indexOf("/recharge") > -1) {
                return;
            }

            // 非首页需要登录后才弹框
            if (!(window.location.href.indexOf("/homepage") > -1 || this.$route.query.path === "/")) {
                if (!localStorage.token) {
                    return
                }
            }

            // 新的活动需要是 非接口用户 才弹出框
            if (this.$store.state.userData.show_active_api_json) {
                return;
            }

            // 弹框已经谈过一次, 且不满足再弹的条件
            const hasHandle = () => {
                // 不弹框
            }

            // 未弹过弹框, 或已经弹过, 但满足再弹的条件
            const noHandle = () => {
                this.newBindStoreDialog.show = true;
            }

            // noTips 表示不再弹出
            let noTips = localStorage.getItem("noTipsFive")
            let noTipsTIme = localStorage.getItem("noTipsTimeFive")

            if (noTips && noTipsTIme) {
                if ((new Date()).valueOf() > noTipsTIme) {
                    noTips = false; // 超过了 5 小时则表示可以弹出
                }
            }

            if (!noTips) {
                noHandle()
            }
        },

        // 传入站点网址, 判断当前站点是否是这个网址
        isWebSite(url) {
            if (window.location.href.indexOf(url) > -1) {
                return true;
            }

            return false;
        },
        // 获取菜单列表
        async getNavList() {
            const res = await this.$fetch('/tool/apps/get-nav-list', {}, 'get')
            if (+res.code === 0) {
                // basicMenu
                let codeArr = res.data;
                let headerMenu = [];
                let basicMenu = [];

                this.headerMenu.forEach(item => {
                    let menuItem = {}
                    
                    const onlySiteFits = item.onlySite && window.location.href.indexOf(item.onlySite) > -1
                    if (codeArr.includes(item.showCode) || onlySiteFits) {
                        menuItem = {
                            ...item,
                        }

                        headerMenu.push(menuItem)
                    }
                })

                this.basicMenu.forEach(item => {
                    let menuItem = {}
                    // API接口 在数据中清除
                    if (codeArr.includes(item.showCode) && !["API接口"].includes(item.name)) {
                        menuItem = {
                            ...item,
                            children: [],
                        }

                        item.children.forEach(cItem => {
                            if (codeArr.includes(cItem.showCode)) {
                                menuItem.children.push(cItem)
                            }
                        })

                        basicMenu.push(menuItem)
                    }
                })

                this.$store.commit('setMenu', {
                    headerMenu,
                    basicMenu,
                })

                this.showTopNav = true
            }
        },

        getHeaderMenu(headerMenu) {
            let result = []
            headerMenu.forEach(item => {
                if (item.showTemplate) {
                    if ([2].includes(+item.showTemplate)) {
                        result.push(item)
                    }
                } else {
                    result.push(item)
                }
            })

            return result;
        },
    },
    created() {
        this.getNavList()
    },
    mounted () {
        this.curLocation = window.location.href 

        window.showQrCode = (url) => {
            this.qrCodeDialog.exData = { url }
            this.qrCodeDialog.show = true
        }

        window.showPushQrCode = (info, title = "推送成功") => {
            this.pushQrCodeDialog.exData = { info, title }
            this.pushQrCodeDialog.show = true
        }

        this.$store.dispatch('getNavList')

        this.$bus.on('openReg', configData => this.openReg(configData))
        this.$bus.on('openLogin', () => this.openLogin())
        window.openLogin = () => {
            this.openLogin()
        }

        if (this.$store.state.userData.uid) {
            this.$refs.socket.setSocket();
        }

        // 在进入 路由拦截的那几个路由的时候, 如果要求打开登录框, 则这里手动打开, 为刷新数据登录后还刷新了页面
        if (this.$store.state.loginModal) {
            this.openLogin()
        }

        this.openBindStore() // 打开弹框
    },
}
</script>

<style lang="scss" scoped>
.navbar {
    //height: 80px;
    height: 160px;
    width: 100%;
    min-width: 1300px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    & > div {
        //width: 100%;
        margin: auto;
        padding: 0 20px;
        //max-width: 1400px;
        min-width: 1300px;
        height: 80px;
    }
}

.half-navbar {
    height: 80px;
    // height: 160px;
    width: 100%;
    min-width: 1300px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    & > div {
        //width: 100%;
        margin: auto;
        padding: 0 20px;
        //max-width: 1400px;
        min-width: 1300px;
        height: 80px;
    }
}

.top-banner {
    height: 80px;
    text-align: center;
    overflow: hidden;
    background: linear-gradient(to right, #ff6213, #ff5826);
    padding: 0;
    margin: 0 auto;
    img {
        height: 100%;
    }
}
.navbar-list {
    .router-link-active,
    .customer-link-active {
        background: #399dfe;
        color: #fff;
        &.no-customer-link-active {
            color: #333;
            background-color: transparent;
        }
    }
}
.nav-top-left {
    img {
        height: 50px;
    }
    a {
        font-size: 16px;
        color: #333;
        padding: 0 12px;
        display: inline-block;
        line-height: 36px;
        border-radius: 4px;
        &:first-child {
            margin-left: 30px;
        }
    }
}
.nav-top-right {
    color: #333;
    & > div {
        margin: 0 10px;
    }
}
.dropdown-phone {
    padding: 10px 10px;
}
.service {
    position: absolute;
    top: 400px;
    right: 0;
    z-index: 999;
    padding: 20px 0;
    background-color: #409eff99;
    .scaling {
        writing-mode: vertical-rl;
    }
    .service-card {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding-right: 20px;
        padding-left: 10px;
        img {
            width: 20px;
            position: relative;
            top: -1px;
        }
    }
}
.btn-service {
    position: fixed;
    right: 40px;
    bottom: 40px;
    z-index: 999;
    font-size: 24px;
}
.top-vip {
    height: 20px;
    position: relative;
    margin-left: 5px;
}
</style>

<style>
.service .el-card__body {
    padding-left: 10px;
    padding-right: 0;
}
.slide-service-enter-active {
    transition: all 1s ease;
}
.slide-service-leave-active {
    transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-service-enter, .slide-service-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
    transform: translateX(20px);
    opacity: 0;
}
</style>

<style lang="scss" scoped>
span.has-tips {
    position: relative;

    > a:first-child {
        margin-left: 0;
    }

    .tips-content {
        position: absolute;
        top: -20px;
        right: -10px;
        font-size: 12px;
        padding: 0 3px;
        color: #fff;
        background-color: rgba(217, 0, 27, 1);
        border-radius: 20px;
    }
}
</style>

