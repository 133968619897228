import Vue from 'vue'
export const enums = {
    source_img: {
        'taobao': 'http://baoyitech.oss-cn-hangzhou.aliyuncs.com/d366fbecb3070da59c6ac90d40445efa1565603098223',
        'tmall': 'http://baoyitech.oss-cn-hangzhou.aliyuncs.com/de8fab7e33f6f53015f071982d9b59151565602782600',
        'jd': 'http://baoyitech.oss-cn-hangzhou.aliyuncs.com/35d4632874e9db096249b1a9d10538731565602788040',
        'pdd': 'http://baoyitech.oss-cn-hangzhou.aliyuncs.com/a8dfad4c4877048e77f2360bd27e7de81565602785189',
        'albb': 'https://pic.rmb.bdstatic.com/bjh/829656a456316b64f352ff5807ee1b7a.png',
        'ks': 'https://pic.rmb.bdstatic.com/bjh/0a128b0fbca8510dae9672cb1e6eed12.png',
        'dyxd': 'https://pic.rmb.bdstatic.com/bjh/9174e50b0baf24eb29e53071d9b7c0fe.png',
        'other': 'http://baoyitech.oss-cn-hangzhou.aliyuncs.com/48827e86385eac0954b9fb5e4b0aa7831565602790457',
    },

    // 申请底单状态
    c_o_status: {
        0: '待审核',
        1: '已处理',
        2: '已驳回',
    },

    shop_type_img: {
        pdderp: 'https://ae05.alicdn.com/kf/H185856daccb04848a1a317d5e735a50cJ.png',
        jderp: 'https://ae05.alicdn.com/kf/H875cd98aefb54f949572ee806cb976a6N.png',
        tberp: 'https://ae05.alicdn.com/kf/H077419f6ce69457fa757a101c9d0341fT.png',
        dyerp: 'https://ae05.alicdn.com/kf/H57686ace7391498bad81f8d29b3409c0P.png',
        kserp: 'https://ae05.alicdn.com/kf/Ha3504cade9594dec8ee077576d2b7a99I.png',
    },
    shop_type_source: {
        pdd: 'pdd',
        jd: 'jd',
        tb: 'taobao',
        dy: 'other',
        ks: 'other',
    }
}

for (const key in enums) {
    Vue.prototype[key] = enums[key]
}
