import Vue from 'vue'
import Router from 'vue-router'
import store from './store'
import { MessageBox } from 'element-ui'
import { setSeo } from './plugins/seo'
import axios from 'axios'
import utils from './utils'
import { fetch } from "@/index/api/index";
// 解决重复点击报错
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)

const chunkPath = (path, folder, meta) => {
    return {
        path,
        name: path,
        meta: meta ? meta : {},
        component: () => import(/* webpackChunkName: "[request][index]" */ `./views/${folder}.vue`)
    }
}

/**
 * 路由里面不要带 message 这个字符(例如: /messageCenter 要改成 /newsCenter). 会有刷新丢失路由的 BUG, 我是二开我也不会知道为啥, 希望后来人不要踩坑
 * 2021-07-20 10:43 eatcr 留
 */
const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        // {
        //     path: '/',
        //     redirect: utils.isToolMainSite() ? '/homepage' : '/elTool'
        // },
        chunkPath('/', 'beian/index'),
        chunkPath('/homepage', 'homepage/index', { hidden: true }),
        chunkPath('/home', 'checkAccount/home'),
        chunkPath('/checkAccount', 'home'),
        chunkPath('/elTool', 'elTool/index', { hidden: true }),
        chunkPath('/recharge', 'recharge'),
        chunkPath('/personCenter', 'personCenter'),
        chunkPath('/newsCenter', 'messageCenter/index'),
        chunkPath('/marking', 'marking'),
        chunkPath('/busQuery', 'busQuery'),
        chunkPath('/tbguest', 'tbguest'),
        chunkPath('/keyRank', 'keyRank'),
        chunkPath('/taokit', 'taokit/index'),
        chunkPath('/taokit/taoCodeParse', 'taokit/taoCodeParse/index', { activeMenu: '/taokit' }),
        chunkPath('/taokit/taouid', 'taokit/taouid/index', { activeMenu: '/taokit' }),
        chunkPath('/taokit/makerCheck', 'taokit/makerCheck/index', { activeMenu: '/taokit' }),
        chunkPath('/taokit/pinDetail', 'taokit/pinDetail/index', { activeMenu: '/taokit' }),
        chunkPath('/taokit/1688Detail', 'taokit/1688_detail/index', { activeMenu: '/taokit' }),
        chunkPath('/taokit/tblm', 'taokit/tblm/index', { activeMenu: '/taokit' }),
        chunkPath('/taokit/tbpp', 'taokit/tbpp/index', { activeMenu: '/taokit' }),
        chunkPath('/taokit/taoPageInfo', 'taokit/taoPageInfo/index', { activeMenu: '/taokit' }),
        chunkPath('/taokit/douPageInfo', 'taokit/douPageInfo/index', { activeMenu: '/taokit' }),
        chunkPath('/taokit/phoneSearch', 'taokit/phoneSearch/index', { activeMenu: '/taokit' }),
        chunkPath('/taokit/createQr', 'taokit/createQr/index', { activeMenu: '/taokit' }),
        chunkPath('/taokit/parseQr', 'taokit/parseQr/index', { activeMenu: '/taokit' }),
        chunkPath('/taokit/phonefee', 'taokit/phonefee/index', { activeMenu: '/taokit' }),
        chunkPath('/taokit/baseCheck', 'taokit/baseCheck/index', { activeMenu: '/taokit' }),
        chunkPath('/taokit/affordable', 'affordable/index', { activeMenu: '/taokit' }),
        chunkPath('/interface', 'interface'),
        chunkPath('/transformData', 'transformData'),
        chunkPath('/vip', 'vip/index'),
        chunkPath('/weightSearch', 'weightSearch/index'),
        chunkPath('/weightpush', 'weightpush/index'),
        chunkPath('/surmise', 'surmise/index'),
        chunkPath('/tagSearch', 'tagSearch/index'),
        chunkPath('/lexicon', 'lexicon/index'),
        chunkPath('/lexRecommend', 'lexicon/index'),
        chunkPath('/lexTaobaoTag', 'lexicon/index'),
        chunkPath('/foreignTao', 'foreignTao/index'),
        chunkPath('/validPicture', 'validPicture/index'),
        chunkPath('/taokouling', 'taokouling/index'),
        chunkPath('/appraise', 'appraise/index'),
        chunkPath('/goodsAnalysis', 'goodsAnalysis/index'),
        chunkPath('/checkNum/dsrCal', 'checkNum/dsrCal'),
        chunkPath('/checkNum/firstPause', 'checkNum/firstPause'),
        chunkPath('/flow/taobao', 'flow/taobao'),
        chunkPath('/flow/jd', 'flow/jd'),
        chunkPath('/flow/pdd', 'flow/pdd'),
        chunkPath('/flow/dy', 'flow/dy'),
        chunkPath('/flow/tasklist', 'flow/tasklist'),
        chunkPath('/store/list', 'damaijia/list/index'),
        chunkPath('/store/list/details/:id', 'damaijia/list/details', {}),
        chunkPath('/store/package', 'damaijia/package/index'),
        chunkPath('/store/payment', 'damaijia/payment/index'),
        chunkPath('/store/orderList', 'damaijia/orderList/index'),
        chunkPath('/traceless', 'traceless/index'),
        chunkPath('/traceless/pdd', 'traceless/pdd'),
        chunkPath('/traceless/1688', 'traceless/1688'),
        chunkPath("/ww-label-weight", "ww_label_weight/index"),
        chunkPath('/pddScreen', 'markPdd/index'),
        chunkPath('/authCheck', 'authCheck/index'),
        // chunkPath('/storeManage', 'storeManage/index'),
        chunkPath('/dy-first-page', 'dy_first_page/index'),
        chunkPath('/dy-check', 'dy_check/index'),
        chunkPath('/push/also-like', 'push/also_like/index', { activeMenu: '/weightpush' }),
        chunkPath('/push/mobile-small', 'push/mobile_small/index', { activeMenu: '/weightpush' }),
        chunkPath('/push/also-like-home', 'push/also_like_home/index', { activeMenu: '/weightpush' }),
        chunkPath('/push/also-like-cart', 'push/also_like_cart/index', { activeMenu: '/weightpush' }),
        chunkPath('/push/my-taobao', 'push/my_taobao/index', { activeMenu: '/weightpush' }),
        // 实时销量监控
        chunkPath('/monitor/competitive', 'monitor/competitive/index'),
        // SKU 销量监控
        chunkPath("/monitor/competitive-sku", "monitor/competitive_sku/index"),
        // 价格监控
        chunkPath("/monitor/competitive-money", "monitor/competitive_money/index"),
        // 信息修改历史
        chunkPath("/monitor/competitive-record", "monitor/competitive_record/index"),
        // 宝贝点击率
        chunkPath("/monitor/competitive-click", "monitor/competitive_click/index"),
        // 引流词分析
        chunkPath("/monitor/competitive-flow", "monitor/competitive_flow/index"),
        chunkPath('/monitor/price', 'monitor/price/index'),
        chunkPath('/monitor/detail', 'monitor/detail/index'),
        chunkPath('/monitor/record', 'monitor/record/index'),
        chunkPath('/monitor/annual-fee', 'monitor/annual_fee/index'),
        // chunkPath('/tbmobile/:type', 'tbmobile/index'),
        // chunkPath('/head_screen/:type', 'head_screen/index')
        // chunkPath('/checkNum/similarImport', 'checkNum/similarImport'),
        chunkPath('/checkNum/similarImport', 'first_flow/index'),
        chunkPath('/tbmobile/:type', 'first_flow/index'),
        chunkPath('/first-flow-dy/:type', 'first_flow_dy/index'),
        chunkPath('/first-flow-pdd/:type', 'first_flow_pdd/index'),
        chunkPath('/first-flow-albb', 'first_flow_albb/index'),
        chunkPath('/head_screen/:type', 'first_flow/index'),
        chunkPath('/agent-join', 'agent_join/index'),
        chunkPath("/promotion-manage", "promotion_manage/index"),
        chunkPath("/buy-package", "buy_package/index"),
        chunkPath("/branch-register", "branch_register/index"),
        chunkPath('/order-alipay', 'orderAlipay/index'),
        // chunkPath('/chrome111-extensions-download', 'chrome_extensions_download/index'),
    ]
})

router.beforeEach((to, from, next) => {
    // 以 / 为结尾的时候, 清除 / 结尾
    if (to.path.length > 1 && to.path[to.path.length - 1] === '/') {
        next({
            path: to.path.slice(0, to.path.length - 1),
            query: { ...to.query },
            params: { ...to.params },
            replace: true,
        })
    }

    setSeo(to)

    if (store.state.configData.proxy_info && store.state.configData.proxy_info.site_title) {
        document.title = store.state.configData.proxy_info.site_title
    }

    if (store.state.configData.proxy_info && store.state.configData.proxy_info.site_ico) {
        document.querySelector('link[rel="icon"]').href
                = "http://" + store.state.configData.static_url + "/" +  store.state.configData.proxy_info.site_ico
    }

    if (to.query.compile == 1) {
        localStorage.compile = 1
        next()
    }
    if (to.query.spm) {
        localStorage.spm = to.query.spm
        axios({
            method: 'post',
            url: '/crmlog/v1/visit',
            params: {
                promotion_code: to.query.spm
            }
        })
        next()
    }
    if (to.path.indexOf('/noOpen') > -1) {
        MessageBox({
            title: '消息',
            message: '该功能调整中，目前仅供内测用户使用',
            confirmButtonText: '确定'
        })
        next(false)
        return
    }
    // 处理各个校验功能是否能跳转
    let pathCodeObj = {
        "/home": "searchplus",
        "/checkAccount": "searchplusjichu",
        "/taokit/baseCheck": "search",
        "/taokit/makerCheck": "getBlackNumber",
        "/taokit/affordable": "searchplusfree",
    }
    if (Object.keys(pathCodeObj).includes(to.path)) {
        fetch('/api/proxy/web/check/user', { code: pathCodeObj[to.path] }).then(res => {
            console.log({ res })
            const { code, msg } = res;

            if (+code === 0) {
                next();
            } else {
                MessageBox({
                    title: '消息',
                    message: msg,
                    confirmButtonText: '确定'
                })
                next({
                    path: "no-page",
                    query: { ...to.query },
                    params: { ...to.params },
                    replace: true,
                })
            }
        })

        return;
    }
    if (to.path) {
        if (window._hmt) {
            window._hmt.push(['_trackPageview', '/#' + to.fullPath])
        }
    }
    if (
        to.path.indexOf('/personCenter') > -1 ||
        to.path.indexOf('/busQuery') > -1 ||
        to.path.indexOf('/recharge') > -1
    ) {
        if (localStorage.phone) {
            next()
            return
        } else {
            window.openLogin()
            next(false)
        }
    } else {
        next()
        return
    }
})

export default router
