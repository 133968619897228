<template>
    <div id="app" v-loading="loading">
        <headerTopMain v-if="isToolMainSite() && showHeader" ref="header"></headerTopMain>
        <header-top v-if="!isToolMainSite() && showHeader" ref="header"></header-top>
        <div class="app-wrap" :class="{ top140: !isToolMainSite() }">
            <siderBar v-if="isToolMainSite()"></siderBar>

            <router-view class="app-router"
                :class="[$route.path.split('/')[$route.path.split('/').length - 1]]"></router-view>

            <div v-if="configData.is_proxy_site == 0" class="ba-bottom">
                备案号：<a class="fc-333" target="_blank" href="https://beian.miit.gov.cn/">豫ICP备19019616号-1</a>
            </div>
            <div v-if="configData.is_proxy_site != 0 && configData.proxy_info && configData.proxy_info.site_icp"
                class="ba-bottom">
                备案号：<a class="fc-333" target="_blank" href="https://beian.miit.gov.cn/">{{
        configData.proxy_info.site_icp
    }}</a>
            </div>
            <backtop target="#app .app-wrap" :right="160" :bottom="60" style="width: 80px;">
                <div class="backtop-warp" style="height: 100%;background-color: #f2f5f6;text-align: center;"
                    title="回到顶部">
                    <!-- <img style="width: 40px;" :src="require('@/index/assets/img/top2.png')" /> -->
                </div>
            </backtop>
        </div>
    </div>
</template>

<script>
import headerTop from './components/header'
import headerTopMain from './components/header/index'
import siderBar from './components/header/siderBar'
import appConfig from './mixin/appConfig'
import Cookies from 'js-cookie'
import Backtop from './components/BackTop'
import dayjs from "dayjs";
import * as customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)

export default {
    name: 'app',
    mixins: [appConfig],
    components: {
        headerTop,
        headerTopMain,
        siderBar,
        Backtop,
    },
    data() {
        return {
            loc_href: location.href || '',
            loading: false,
            showHeader: false,
            packageTotalCode: null, // 所有含有套餐的
            packageBuyCode: null, // 已购买套餐
        }
    },
    methods: {
        tipForce() {
            // 确定不是在预渲染环境下
            if (!localStorage.compile) {
                if (!localStorage.force1) {
                    this.$alert('权重推送已经恢复正常，但是有不稳定请一次推送一个账号', '重要通知', {
                        confirmButtonText: '查看',
                        showClose: false,
                        callback: action => {
                            if (action == 'confirm') {
                                localStorage.force1 = 1
                                window.open('/weightpush')
                            }
                        },
                    })
                }
            }
        },
        openVip(configData) {
            this.$router.push('/vip')
            return
            this.$confirm(
                `开通会员VIP需要消耗${configData.upgrade_vip_point}点券，VIP专享价格：旺旺照妖镜${configData.api_setting.search.vip_point}点券/次，旺旺强行打标${configData.api_setting.wwfscreen.vip_point}点券/次`,
                '开通会员',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            ).then(async () => {
                const data = await this.$fetch('/tool/members/vip')
                if (data.code == 0) {
                    this.$message({
                        type: 'success',
                        message: '开通成功',
                    })
                    this.$store.dispatch('upDateUser')
                }
            })
        },
        openRecharge() {
            this.$alert('账户余额不足', {
                message: '亲，充值10元即可获得1000点券<br>添加客服有概率获得额外点券哦',
                title: '账户余额不足',
                center: true,
                dangerouslyUseHTMLString: true,
                distinguishCancelAndClose: true,
                confirmButtonText: '充值',
                // cancelButtonText: '去邀请'
            }).then(() => {
                // this.$router.push('/recharge')
                window.open("/recharge")
            })
        },

        async getBeian() {
            this.loading = true
            try {
                const res = await this.$fetch('/tool/apps/get-beian', {})

                return res.data
            } finally {
                this.loading = false
            }
        },

        async checkDiscountPackage(code) {
            // 登录状态下才需要校验套餐, 按理说没登陆无法进入这个方法, 但这里额外加一个判断
            if (!localStorage.token) {
                this.$store.dispatch("setLogout")
                setTimeout(() => {
                    window.openLogin()
                }, 1000);
                return false;
            }

            // 已存在套餐为 null, 则获取已存在套餐
            if (!this.packageBuyCode) {
                await this.getPackage()
            }

            // 当前套餐不在在套餐包含的工具中, 则返回 false
            if (!this.packageTotalCode.includes(code)) {
                return false;
            }

            // 当前套餐在已购买套餐中, 则返回 false
            if (this.packageBuyCode.includes(code)) {
                return false;
            }

            /**
             * 当前功能在套餐中, 且不在已购买套餐中, 则根据产品规则弹框
             */

            // 获取当前功能的使用次数
            let codeUsedNum = localStorage.getItem(`${code}_used_num`)
            if (!codeUsedNum) {
                // 没有使用次数, 则使用次数 + 1
                localStorage.setItem(`${code}_used_num`, 1)
                return false;
            }

            if (codeUsedNum < 3) {
                // 存在使用次数, 但没有达到 3 次, 则使用次数加 1
                localStorage.setItem(`${code}_used_num`, +codeUsedNum + 1)
                return false;
            }

            // 使用次数已经达到3次, 则判断是否满足弹框逻辑
            let codeDialogDate = localStorage.getItem(`${code}_dialog_date`)
            let codeDialogDateArrStr = localStorage.getItem(`${code}_dialog_date_arr`)
            let codeDialogDateArr = [];
            if (codeDialogDateArrStr) {
                codeDialogDateArr = JSON.parse(codeDialogDateArrStr)
            }
            let beyondArr = []; // codeDialogDateArr 内距离现在时间7天内的日期

            let weekStartTime = dayjs().subtract(7, 'day')
            codeDialogDateArr.forEach(item => {
                if (weekStartTime.isBefore(dayjs(item, "YYYY-MM-DD HH:mm:ss"))) {
                    beyondArr.push(item)
                }
            })

            // 不存在弹出时间, 则直接弹框并记录最新弹出时间, 并在最多只有三项的弹出时间数组内记录最新的弹出时间, 删除最老的弹出时间
            if (!codeDialogDate) {
                this.packageTipsDialog(code)
                let nowDate = dayjs()
                let nowDateFormat = nowDate.format("YYYY-MM-DD HH:mm:ss")
                localStorage.setItem(`${code}_dialog_date`, nowDateFormat)
                localStorage.setItem(`${code}_dialog_date_arr`, JSON.stringify([nowDateFormat]))

                return false;
            }

            let endTime = dayjs().endOf("day") // 当前的最晚时间 即一般的 23:59:59

            // 存在弹出时间, 但没过今天零点, 即当天已经弹出过一次了, 不再弹出
            if (dayjs(codeDialogDate, "YYYY-MM-DD HH:mm:ss").isBefore(endTime)) {
                return false;
            }

            // 存在弹出时间, 且过了今天零点, 且数组内含有三个7天内的数据, 不再弹出
            if (beyondArr.length >= 3) {
                return false;
            }

            // 存在弹出时间, 且已经过了今天零点, 也就是一天内未再弹出, 且时间数组内不存在三个在7天内的弹出数据, 则直接弹出, 且记录最新的弹出时间, 删除最老的弹出时间
            if (beyondArr.length < 3) {
                this.packageTipsDialog(code)
                let nowDate = dayjs()
                let nowDateFormat = nowDate.format("YYYY-MM-DD HH:mm:ss")
                codeDialogDateArr.push(nowDateFormat)
                if (codeDialogDateArr.length > 3) {
                    codeDialogDateArr.shift()
                }

                localStorage.setItem(`${code}_dialog_date`, nowDateFormat)
                localStorage.setItem(`${code}_dialog_date_arr`, JSON.stringify(codeDialogDateArr))

                return false;
            }
        },

        // 弹框
        async packageTipsDialog(code) {
            let res = await this.getPopupMoney(code)
            this.$notify({
                title: '温馨提示',
                dangerouslyUseHTMLString: true,
                message: `
                    <div>购买该功能套餐可以低至${res.unPrice}点券/次</div>
                    <a href="/buy-package" target="_blank" style="color: #ff0028;">点击去购买套餐>></a>
                `
            });
        },

        async getPopupMoney(code) {
            const res = await this.$fetch('/api/proxy/getPopup', {
                tool_code: code
            }, 'post')
            return res.data;
        },

        async getPackage() {
            // this.loading = true
            try {
                const res = await this.$fetch('/api/proxy/getPackageCode', {}, "post")

                let info = res.data

                this.packageTotalCode = info.total
                this.packageBuyCode = info.buy
            } finally {
                // this.loading = false
            }
        },

    },
    watch: {
        $route(newValue, oldValue) { },
    },
    async created() {
        if (this.$store.state.phone && this.$store.state.userData.username) {
            // / 逻辑交给 /beian 处理, 其它路由不处理
            // this.$router.replace({
            //     path: '/elTool',
            // })
        } else {
            let res = await this.getBeian() // +res.is_beian === 0
            // 未登录且 res.is_beian 为 0  则跳转备案页
            if (+res.is_beian === 0) {
                this.$router.replace({
                    path: '/',
                })
            } else {
                // 未登录且 res.is_beian 不为 0 , 则保持不变
                // 如果用户是跳转了其它路由, 则会进入想要的路由, 但如果用户是根路由, 则会进入备案页面
                // 所以如果用户是根路由, 则跳转 elTool

                // console.log("window.location.href.split(window.location.host)")
                let arr = window.location.href.split(window.location.host)
                // console.loeg(arr[arr.length - 1])
                if (!arr[arr.length - 1] || arr[arr.length - 1] === "/") {
                    this.$router.replace({
                        path: '/homepage',
                    })
                }
            }
        }
        this.showHeader = true
    },
    mounted() {
        // this.tipForce()
        this.$bus.on('openVip', configData => this.openVip(configData))
        // this.$bus.on('openLogin', () => this.$refs.header.openLogin())
        // window.openLogin = () => {
        //     this.$refs.header.openLogin()
        // }
        window.openRecharge = () => {
            this.openRecharge()
        }
        if (Cookies.get('vtu_spm')) {
            localStorage.spm = Cookies.get('vtu_spm')
        }

        window.checkDiscountPackage = (code) => {
            this.checkDiscountPackage(code)
        }

    },
}
</script>

<style lang="scss">
.el-backtop {
    position: fixed;
    background-color: #fff;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    color: #409eff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    box-shadow: 0 0 6px rgb(0 0 0 / 12%);
    cursor: pointer;
    z-index: 5;
}

.backtop-warp {
    width: 3.5rem !important;
    height: 3.5rem !important;
    line-height: 80px;
    border-radius: 50%;
    background-image: url(./assets/img/top2.png);
    background-size: 100% 100%;

    &:hover {
        background-image: url(./assets/img/top1.png);
    }
}
</style>
<style lang="scss" scoped>
.app-wrap {
    position: absolute;
    //top: 81px;
    top: 161px;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    overflow: scroll;
}

.ba-bottom {
    // position: absolute;
    bottom: 20px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    width: 100%;
    color: #333;
    font-size: 14px;
}

.app-router {
    padding-left: 165px;
}

// .app-router {
//     position: absolute;
//     top: 70px;
//     left: 165px;
//     right: 0;
//     bottom: 0;
// }</style>

<style>
.el-loading-mask {
    /* 之前不知道为什么给全屏 loading 加上了白色背景, 这里去掉是因为全屏白色背景影响到了用户交互, 不美观, 旺旺照妖镜那里尤为突出 */
    /*background-color: #fff !important;*/
}

.el-loading-mask.all-loading .el-loading-spinner {
    overflow: hidden;
    padding: 30px 0;
    background: #fff;
    width: 260px;
    left: 50%;
    margin-left: -130px;
    border-radius: 6px;
}

/**
 * 插件下载页的特殊 class
 */
.chrome-extensions-download {
    padding-left: 0 !important;
}
</style>
