<template>
    <div>
        <el-dialog title="购买套餐" :visible.sync="dialog" width="1080px" top="150px">
            <div v-loading="loading">
                <p class="tips">提示: 勾选表格内的套餐即可直接购买</p>
                <!-- 信息展示 -->
                <div>
                    <el-table :data="tableData" border :span-method="objectSpanMethod" style="width: 100%">
                        <el-table-column prop="package_cate" width="100px"></el-table-column>
                        <el-table-column prop="package_name" label="功能简介">
                            <template slot-scope="scope">
                                <div>
                                    <p style="display: flex; align-items: center;">
                                        <span style="margin-right: 6px;">{{ scope.row.package_name }}</span>
                                        <el-tag v-if="hotTool.includes(scope.row.package_name)" type="danger" size="mini">hot</el-tag>
                                    </p>
                                    <p style="font-size: 12px; color: #888;">{{ scope.row.package_desc }}</p>
                                    <p v-if="scope.row.package_url" style="text-align: right;">
                                        <a :href="scope.row.package_url" target="_blank" style="color: #009dd8;">去使用>></a>
                                    </p>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="可用次数/天数" width="160px" align="center">
                            <template slot-scope="scope">
                                <!-- 无限制数据 -->
                                <div v-if="scope.row.isWuxianzhi">
                                    <span style="color: #f00;">{{ scope.row.wuxianzhiDesc }}</span>
                                </div>
                                <!-- 竞品监控数据 -->
                                <div v-else-if="scope.row.package_cate === '竞品监控'">
                                    <p style="color: #f00">{{ scope.row.package_function_codes[0].tool_count_desc }}</p>
                                    <p style="color: #f00">{{ scope.row.package_function_codes[0].tool_count_desc_sub }}</p>
                                </div>
                                <!-- 导出淘宝订单数据 -->
                                <div v-else-if="scope.row.package_cate === '导出淘宝订单'">
                                    <span style="color: #f00;">{{ scope.row.package_tool_desc }}</span>
                                </div>
                                <!-- 正常渲染 -->
                                <div v-else>
                                    <span style="color: #f00;" v-if="scope.row.package_function_codes && scope.row.package_function_codes.length">{{ scope.row.package_function_codes[0].tool_count_desc }}</span>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="单独购买价格（月30天、季90天、年365天）" width="340px" align="center">
                            <template slot-scope="scope">
                                <!-- 无限制数据 -->
                                <div v-if="scope.row.isWuxianzhi" style="padding: 10px 0;">
                                    <div>
                                        <p style="display: inline-block; padding: 3px 6px; border-radius: 30px; color: #fff; background-color: #67C23A;">购买套餐免费使用</p>
                                    </div>
                                    <p style="color: #F59A23;">{{ scope.row.moneyDesc }}</p>
                                    <p style="font-size: 12px; color: #888;">免费使用时间和购买套餐的时间一致</p>
                                </div>
                                <!-- 竞品监控数据 -->
                                <div v-else-if="scope.row.package_cate === '竞品监控'" @click="$router.push('/monitor/annual-fee')">
                                    <el-checkbox v-model="checkboxJpjk">{{ scope.row.jpjkMoney }}</el-checkbox>
                                </div>
                                <!-- 导出淘宝订单数据 -->
                                <!--<div v-else-if="scope.row.package_cate === '导出淘宝订单'">-->
                                <!--    <p @click="exportBuy" style="display: inline-block; font-size: 12px; color: #fff; padding: 1px 30px; background-color: #f00; border-radius: 3px; cursor: pointer;">自定义条数</p>-->
                                <!--</div>-->
                                <!-- 正常渲染 -->
                                <div v-else style="width: 100%; display: flex; align-items: center; justify-content: space-around;">
                                    <div style="padding: 0 10px;">
                                        <!--package_month_price + site_month_profit + package_month_price_profit-->
                                        <el-checkbox v-model="scope.row.monthChecked" @change="checkedChange">￥{{
                                                calcAdd(calcAdd(scope.row.package_month_price, scope.row.site_month_profit), scope.row.package_month_price_profit)
                                            }}/月
                                        </el-checkbox>
                                        <p v-if="scope.row.package_function_codes && scope.row.package_function_codes.length" style="font-size: 12px; color: #E6A23C;">约{{calcDiv(calcAdd(calcAdd(scope.row.package_month_price, scope.row.site_month_profit), scope.row.package_month_price_profit), 30 * Number(scope.row.package_function_codes[0].tool_count)).toFixed(3)}}元/次</p>
                                    </div>
                                    <!-- 有些功能没有季的价格 -->
                                    <div v-if="!noSeasonMoney.includes(scope.row.package_code)" style="padding: 0 10px;">
                                        <el-checkbox v-model="scope.row.seasonChecked" @change="checkedChange">￥{{
                                                calcAdd(calcAdd(scope.row.package_season_price, scope.row.site_season_profit), scope.row.package_season_price_profit)
                                            }}/季
                                        </el-checkbox>
                                        <p v-if="scope.row.package_function_codes && scope.row.package_function_codes.length" style="font-size: 12px; color: #E6A23C;">约{{calcDiv(calcAdd(calcAdd(scope.row.package_season_price, scope.row.site_season_profit), scope.row.package_season_price_profit), 90 * Number(scope.row.package_function_codes[0].tool_count)).toFixed(3)}}元/次</p>
                                    </div>
                                    <!-- 有些功能没有年的价格 -->
                                    <div v-if="!noYearMoney.includes(scope.row.package_code)" style="padding: 0 10px;">
                                        <el-checkbox v-model="scope.row.yearChecked" @change="checkedChange">￥{{
                                                calcAdd(calcAdd(scope.row.package_year_price, scope.row.site_year_profit), scope.row.package_year_price_profit)
                                            }}/年
                                        </el-checkbox>
                                        <p v-if="scope.row.package_function_codes && scope.row.package_function_codes.length" style="font-size: 12px; color: #E6A23C;">约{{calcDiv(calcAdd(calcAdd(scope.row.package_year_price, scope.row.site_year_profit), scope.row.package_year_price_profit), 365 * Number(scope.row.package_function_codes[0].tool_count)).toFixed(3)}}元/次</p>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>

                    <!-- 选购功能 -->
                    <div v-show="checkedResult.length" class="buy-total-content" :style="{width: '100%'}">
                        <div class="money-content">
                            <p>
                                <span style="font-size: 16px; color: #333;">选购的功能</span>
                                <span style="font-size: 12px; color: #888; margin-left: 6px;">购买后不支持退款</span>
                            </p>

                            <p style="font-size: 16px; color: #333; margin-top: 20px;">已选取<span style="color: #f00;">{{ checkedResult.length }}</span>个功能</p>

                            <div class="checked-list">
                                <div class="item" v-for="(item, index) in checkedResult" :key="index">
                                    <div class="info">
                                        <p>{{ item.package_name }}</p>
                                        <p>{{ item.tool_count_desc }}</p>
                                    </div>
                                    <div class="money">
                                        <span style="color: #f00;">￥{{ item.showMoney }}</span>
                                        <span v-if="item.checkedType === 'month'">/月</span>
                                        <span v-if="item.checkedType === 'season'">/季</span>
                                        <span v-if="item.checkedType === 'year'">/年</span>
                                    </div>
                                    <i class="remove el-icon-error" @click="removeChecked(item)"></i>
                                </div>
                            </div>
                        </div>

                        <i class="buy-total-content-remove el-icon-error" @click="removeAllChecked()"></i>

                        <div class="buy-button">
                            <p style="font-size: 16px; color: #333;">
                                <span>总金额</span>
                                <span style="font-weight: 600; color: #f00;">￥{{ totalMoney }}</span>
                                <span style="color: #888;">/{{ totalMoney * 100 }}点券</span>
                            </p>
                            <p class="buy-button-el" @click="buyClick">立即购买</p>
                        </div>
                    </div>
                </div>
            </div>
            <!--<div slot="footer" class="dialog-footer text-center">-->
            <!--    <el-button type="danger" :loading="loading" @click="submitClick">保存</el-button>-->
            <!--    <el-button @click="dialog = false">取 消</el-button>-->
            <!--</div>-->
        </el-dialog>
        <order-buy v-if="orderBuyDialog.show" v-model="orderBuyDialog.show" :ex-data="orderBuyDialog.exData" @success="buySuccess" />
    </div>
</template>

<script>
import { calcAdd, calcDiv, calcMul } from '@/index/plugins/calc'
import OrderBuy from "@/index/views/buy_package/_dialog/OrderBuy"

export default {
    components: {
        OrderBuy
    },
    props: {
        value: {
            type: Boolean,
            default: true,
        },
        exData: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    data() {
        return {
            dialog: !!this.value,
            loading: false,
            tableData: [],
            checkedResult: [], // 勾选中的信息
            checkboxJpjk: false,
            noSeasonMoney: ["dygjzksp20", "dycnxh20", "pdddjdsh20", "pddssksp20"], // 没有季度价格的功能 code
            noYearMoney: ["dygjzksp20", "dycnxh20", "pdddjdsh20", "pddssksp20"], // 没有年度价格的功能 code
            hotTool: ["竞品监控", "抖音搜索卡首屏", "抖音猜你喜欢", "旺旺搜索打标", "大数据验号（标签）"], // 热门工具(后台数据), 自定义添加数据的热门由自定义数据决定
            orderBuyDialog: {
                show: false,
                exData: {}
            },
        }
    },
    computed: {
        totalMoney() {
            return this.checkedResult.reduce((total, nextInfo) => {
                return total + nextInfo.showMoney
            }, 0)
        }
    },
    watch: {
        dialog(val) {
            if (!val) this.$emit("input", val);
        },
    },
    created() {
        this.getData();
    },
    methods: {
        calcAdd,
        calcDiv,
        calcMul,
        async getData() {
            try {
                this.loading = true;
                let res = await this.$fetch("/api/proxy/web/function/package/detail", {
                    package_code: this.exData.code,
                }, "get");

                if (res.code === 0) {
                    let info = res.data;

                    this.dataHandle(info);
                }
            } finally {
                this.loading = false;
            }
        },

        // 数据处理
        dataHandle(info) {
            /**
             * 单个工具的处理
             */

            // 1. 取得分类数据
            let { resultObj: packageCateObj } = this.classifyHandle(info.list, "package_cate");
            // 2. 根据分类数据, 处理分类数据的 rowspan 和 colspan
            this.rowColHandle(packageCateObj, "cateRowSpan", "cateColSpan");
            // 3. 取得功能数据, 每个功能放一起
            let packageArr = [];
            Object.keys(packageCateObj).forEach(item => {
                let { resultArr, resultObj } = this.classifyHandle(packageCateObj[item], "package_name");
                // 处理功能数据的 rowspan 和 colspan
                this.rowColHandle(resultObj, "toolRowSpan", "toolColSpan");

                packageArr = [...packageArr, ...resultArr];
            });

            packageArr.forEach(item => {
                // 处理 checked
                item.monthChecked = false;
                item.seasonChecked = false;
                item.yearChecked = false;
            });

            console.log({ packageArr });
            this.tableData = packageArr;
        },

        // 传入对象数组, 和对象数组的某个 key, 把数组根据 key 相同的排在一起重新排序
        classifyHandle(arr, key) {
            let keyNameObj = {}

            arr.forEach(item => {
                let keyName = item[key]
                if (!keyNameObj[keyName]) {
                    keyNameObj[keyName] = []
                }
            })

            // 把 key 一样的放进一个数组里面
            Object.keys(keyNameObj).forEach(keyName => {
                arr.forEach(item => {
                    if (item[key] === keyName) {
                        keyNameObj[keyName].push(item)
                    }
                })
            })

            let resultArr = []

            Object.keys(keyNameObj).forEach(item => {
                resultArr = [...resultArr, ...keyNameObj[item]]
            })

            return {
                resultArr,
                resultObj: keyNameObj
            }
        },

        // 传入经过 classifyHandle 函数处理的对象数据以及 rowspan 和 colspan 的 key, 设置他们的 rowspan 和 colspan (合并表格项要用)
        rowColHandle(obj, rowspanKey, colspanKey) {
            // 处理每一项的 rowspan 和 colspan
            Object.keys(obj).forEach(keyName => {
                if (obj[keyName].length) {
                    obj[keyName].forEach((item, index) => {
                        if (index) {
                            item[rowspanKey] = 0
                            item[colspanKey] = 0
                        } else {
                            item[rowspanKey] = obj[keyName].length
                            item[colspanKey] = 1
                        }
                    })
                }
            })
        },

        checkedChange(value) {
            this.getCheckedStatus()
        },

        // 获取选中状态, 返回选中的项的各个数据
        getCheckedStatus() {
            let tableData = this.tableData
            let result = []

            tableData.forEach(item => {
                let checkedInfo = {
                    package_name: item.package_name,
                    package_code: item.package_code,
                    tool_count_desc: item.package_function_codes ? item.package_function_codes[0].tool_count_desc : ''
                }
                if (item.monthChecked) {
                    checkedInfo.checkedType = 'month'
                    checkedInfo.showMoney = calcAdd(calcAdd(item.package_month_price, item.site_month_profit), item.package_month_price_profit)
                    result.push({ ...checkedInfo })
                }
                if (item.seasonChecked) {
                    checkedInfo.checkedType = 'season'
                    checkedInfo.showMoney = calcAdd(calcAdd(item.package_season_price, item.site_season_profit), item.package_season_price_profit)
                    result.push({ ...checkedInfo })
                }
                if (item.yearChecked) {
                    checkedInfo.checkedType = 'year'
                    checkedInfo.showMoney = calcAdd(calcAdd(item.package_year_price, item.site_year_profit), item.package_year_price_profit)
                    result.push({ ...checkedInfo })
                }
            })

            this.checkedResult = [...result]
        },

        removeChecked(info) {
            let tableData = this.tableData
            tableData.forEach(item => {
                if (item.package_code === info.package_code) {
                    item[info.checkedType + 'Checked'] = false
                }
            })
            this.getCheckedStatus()
        },

        removeAllChecked() {
            let tableData = this.tableData
            tableData.forEach(item => {
                item['monthChecked'] = false
                item['seasonChecked'] = false
                item['yearChecked'] = false
            })
            this.getCheckedStatus()
        },

        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
            if (columnIndex === 0) {
                return {
                    rowspan: row.cateRowSpan,
                    colspan: row.cateColSpan
                }
            } else if (columnIndex === 1) {
                return {
                    rowspan: row.toolRowSpan,
                    colspan: row.toolColSpan
                }
            }
        },

        buyClick() {
            if (!this.checkedResult.length) {
                this.$message.warning('选择需要购买的功能')
                return
            }
            this.orderBuyDialog.exData = {
                checkedResult: this.checkedResult,
                totalMoney: this.totalMoney
            }
            this.orderBuyDialog.show = true
        },

        // 购买成功
        buySuccess() {
            this.$emit("buySuccess")
        },

        async submitClick() {
            try {
                this.loading = true;
                const res = await this.$fetch("/tool/members/set-notify-url", this.form, "post");

                if (res.code === 0) {
                    this.$message.success("添加成功");
                    this.$emit("success", this.form);
                    this.dialog = false;
                }

            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.tips {
    font-size: 16px;
    color: #f00;
    margin: 10px 0;
}
</style>

<style lang="scss">
.package-table {
    .el-table .el-table__cell {
        padding: 3px 0;
    }

    .el-table__footer-wrapper, .el-table__header-wrapper {
        overflow: visible;
    }

    .el-table th.el-table__cell {
        overflow: visible;
    }

    .el-table .cell {
        overflow: visible;
    }

    .el-table, .el-table__append-wrapper {
        overflow: visible;
    }

    .table-customize {
        position: relative;
        font-weight: 400;

        .customize-top {
            width: 120px;
            height: 42px;
            position: absolute;
            top: -46px;
            left: -11px;
            text-align: center;
            background: rgba(255, 205, 178, 1)
        }
    }
}
</style>

<style lang="scss" scoped>
.checked-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px 0;

    .item {
        position: relative;
        margin-left: 10px;
        padding-bottom: 16px;
        font-size: 14px;

        .info {
            padding: 6px 0;
            width: 160px;
            text-align: center;
            border-radius: 6px;
            color: #fff;
            background-color: #f00;
        }

        .money {
            text-align: center;
            margin-top: 16px;
        }

        .remove {
            position: absolute;
            right: -12px;
            top: -12px;
            cursor: pointer;
            font-size: 20px;
            color: #333;
            opacity: .3;

            &:hover {
                opacity: .8;
            }
        }
    }
}

.buy-total-content {
    //position: fixed;
    //bottom: 0;
    position: relative;
    margin-top: 20px;
    background-color: #fff;
    border: 1px solid #eee;
    //z-index: 99;

    .money-content {
        padding: 10px;
    }

    .buy-total-content-remove {
        position: absolute;
        right: -12px;
        top: -12px;
        cursor: pointer;
        font-size: 20px;
        color: #333;
        opacity: .3;

        &:hover {
            opacity: .8;
        }
    }

    .buy-button {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        background-color: #f5f5f5;

        .buy-button-el {
            color: #fff;
            background-color: #f00;
            padding: 10px 20px;
            cursor: pointer;
            margin-left: 6px;
        }
    }
}
</style>
