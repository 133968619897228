<template>
    <div class="sider-wrap" v-if="!$route.meta.hidden">
        <div class="siderBar">
            <el-menu
                class="el-menu-vertical"
                background-color="#399dfe"
                text-color="#fff"
                active-text-color="#62b0fe"
                :unique-opened="true"
                :default-active="$route.meta.activeMenu ? $route.meta.activeMenu : $route.path"
                :router="true">
                <!-- API接口不展示 -->
                <div v-for="(item, index) in basicMenu" :key="item.showCode" v-show="showMenu.includes(index) && !['API接口'].includes(item.name)">
                    <el-menu-item
                        v-if="!item.children || item.children.length === 0"
                        :index="item.url"
                        @click="menuItemClick(item.url)"
                        :title="item.name"
                    >
                        <span>{{ item.name }}</span>
                    </el-menu-item>
                    <el-submenu v-else :index="item.url">
                        <template slot="title">
                            <span :title="item.name">{{ item.name }}</span>
                        </template>
                        <template v-for="cItem in item.children">
                            <template v-if="noSidebarShow.includes(cItem.url)">
                            </template>
                            <!-- 授权验号需要是会员且消费满足一定条件才能展示出来 -->
                            <template v-else-if="cItem.name === '授权验号'">
                                <el-menu-item v-if="!!+userData.is_authorize" :key="cItem.showCode" :index="cItem.url" :title="cItem.name" @click="menuItemClick(cItem.url)">
                                    {{ cItem.name }}
                                </el-menu-item>
                            </template>
                            <el-menu-item v-else :key="cItem.showCode" :index="cItem.url" :title="cItem.name" @click="menuItemClick(cItem.url)">
                                {{ cItem.name }}
                            </el-menu-item>
                        </template>
                    </el-submenu>
                </div>
            </el-menu>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
    computed: {
        ...mapState(['userData', 'siderBarCate', "navShowList", "navShowListHasErr", "configData", "basicMenu"]),
        showMenu() {
            // return [0, 1, 2, 3, 4, 5] // 展示全部的菜单
            // 修改这里会影响到礼品发货下单页面的菜单展示
            if (isNaN(this.siderBarCate)) {
                return []
            } else {
                let siderBarCate = []
                for (let i=0; i<this.basicMenu.length; i++) {
                    siderBarCate.push(i)
                }
                return siderBarCate // 菜单新增了一个大项, 则这里的数组项新增 1 个, 且内容一次类推数字 + 1
            }

            // return this.siderBarCate <= 2 ? [0, 1, 2] : [this.siderBarCate]
        },
    },
    data() {
        return {
            // blockMenuRoute: ['/homepage', '/elTool'],  //不显示siderBar路由数组
            subNavList: [
                {
                    href: '/home',
                    icon: 'el-icon-menu',
                    name: '验号中心',
                    children: [
                        {
                            href: "/ww-label-weight",
                            name: "旺旺标签权重",
                            showCode: "ww-label-weight",
                        },
                        {
                            href: '/home',
                            name: '旺旺照妖镜',
                            showCode: "home",
                        },
                        {
                            href: '/weightpush',
                            name: '搜索权重推送',
                            showCode: "weightpush",
                        },
                        {
                            href: '/keyRank',
                            name: '淘宝排名查询',
                            showCode: "keyRank",
                        },
                        {
                            href: "/tagSearch",
                            name: "淘宝号标签查询",
                            showCode: "tagSearch",
                        },
                        {
                            href: '/goodsAnalysis',
                            name: '竞品数据透析',
                            showCode: "goodsAnalysis",
                        },
                        {
                            href: '/traceless',
                            name: '无痕补单',
                            showCode: "traceless",
                        },
                        {
                            href: '/validPicture',
                            name: '防P图验号',
                            showCode: "validPicture",
                        },
                        {
                            href: '/transformData',
                            name: '指数换算',
                            showCode: "transformData",
                        },
                        {
                            href: '/tbguest',
                            name: '淘宝客订单检测',
                            showCode: "tbguest",
                        },
                        {
                            href: '/checkNum/dsrCal',
                            name: 'DSR智能计算器',
                            showCode: "checkNum_dsrCal",
                        },
                        {
                            href: '/taokouling',
                            name: '淘口令生成',
                            showCode: "taokouling",
                        },
                        {
                            href: '/appraise',
                            name: '宝贝评价分析',
                            showCode: "appraise",
                        },
                        {
                            href: '/lexicon',
                            name: 'TOP20w词库',
                            showCode: "lexicon",
                        },
                        {
                            href: '/lexRecommend',
                            name: '搜索下拉推荐词',
                            showCode: "lexRecommend",
                        },
                        {
                            href: '/lexTaobaoTag',
                            name: '旺旺搜索词推荐',
                            showCode: "lexTaobaoTag",
                        },
                        {
                            href: '/checkAccount',
                            name: '大数据验号',
                            showCode: "checkAccount",
                        },
                        {
                            href: '/dy-check',
                            name: '抖音验号',
                            showCode: "dy-check",
                        },
                        {
                            href: '/authCheck',
                            name: '授权验号',
                            showCode: "authCheck",
                        },
                        {
                            href: '/taokit',
                            name: '免费工具箱',
                            showCode: "taokit",
                        }
                    ]
                },
                {
                    href: '/pause',
                    icon: 'el-icon-s-platform',
                    name: '卡首屏',
                    children: [
                        {
                            href: '/checkNum/firstPause',
                            name: '关键词卡首屏',
                            showCode: "checkNum_firstPause",
                        },
                        {
                            href: '/checkNum/similarImport',
                            name: '找相似入口',
                            showCode: "checkNum_similarImport",
                        },
                        {
                            href: '/marking',
                            name: '旺旺强行打标',
                            showCode: "marking",
                        },
                        {
                            href: '/tbmobile/guess',
                            name: '猜你喜欢',
                            showCode: "tbmobile_guess",
                        },
                        {
                            href: '/dy-first-page',
                            name: '抖音卡首屏',
                            showCode: "dy-first-page",
                        },
                        // {
                        //     href: '/foreignTao',
                        //     name: '洋淘秀卡首屏'
                        // },
                        {
                            href: '/first-flow-dy/first-flow',
                            name: '抖音搜索卡首屏',
                            showCode: "first_flow_dy_first_flow"
                        },
                        {
                            href: '/first-flow-dy/guess',
                            name: '抖音猜你喜欢',
                            showCode: "first_flow_dy_guess"
                        },
                        {
                            href: '/pddScreen',
                            name: '拼多多打标',
                            showCode: "pddScreen",
                        },
                        {
                            href: '/first-flow-pdd/said-ok',
                            name: '大家都说好',
                            showCode: "first_flow_pdd_said_ok"
                        },
                        {
                            href: "/first-flow-albb",
                            name: "1688卡首屏",
                            showCode: "head_screen_recommend"
                        },
                        {
                            href: '/first-flow-pdd/first-flow',
                            name: '拼多多卡首屏',
                            showCode: "first_flow_pdd_first_flow"
                        },
                        {
                            href: '/head_screen/recommend',
                            name: '卡首屏系列',
                            showCode: "head_screen_recommend",
                        },
                    ]
                },
                {
                    href: '/tbmobile',
                    icon: 'el-icon-mobile',
                    name: '手淘流量',
                    children: [
                        {
                            href: '/tbmobile/nicegoods',
                            name: '有好货',
                            showCode: "tbmobile_nicegoods",
                        },
                        {
                            href: '/tbmobile/dayshops',
                            name: '每日好店',
                            showCode: "tbmobile_dayshops",
                        },
                        {
                            href: '/tbmobile/iFashion',
                            name: 'iFashion',
                            showCode: "tbmobile_iFashion",
                        },
                        {
                            href: '/tbmobile/robshop',
                            name: '淘抢购',
                            showCode: "tbmobile_robshop",
                        },
                        {
                            href: '/tbmobile/probaby',
                            name: '亲宝贝',
                            showCode: "tbmobile_probaby",
                        },
                        // {
                        //     href: '/tbmobile/tideplay',
                        //     name: '潮玩城'
                        // },
                        // {
                        //     href: '/tbmobile/polehome',
                        //     name: '极有家'
                        // },
                        {
                            href: '/tbmobile/hbooth',
                            name: '首页钻展',
                            showCode: "tbmobile_hbooth",
                        },
                        {
                            href: '/tbmobile/globalshop',
                            name: '全球购',
                            showCode: "tbmobile_globalshop",
                        },
                        // {
                        //     href: '/head_screen/clear',
                        //     name: '手淘清仓流量'
                        // },
                        // {
                        //     href: '/head_screen/netred',
                        //     name: '手淘网红集合'
                        // },
                        {
                            href: '/head_screen/life',
                            name: '生活研究所',
                            showCode: "head_screen_life",
                        },
                        // {
                        //     href: '/head_screen/made',
                        //     name: '中国制造'
                        // },
                        // {
                        //     href: '/head_screen/pickup',
                        //     name: '品牌捡漏'
                        // },
                        // {
                        //     href: '/head_screen/tideplay',
                        //     name: '换新'
                        // },
                        // {
                        //     href: '/head_screen/polehome',
                        //     name: '汇吃'
                        // },
                        // {
                        //     href: '/tbmobile/tbeat',
                        //     name: '淘宝汇吃'
                        // },
                    ]
                },
                {
                    href: '/monitor',
                    icon: 'el-icon-s-platform',
                    name: '监控中心',
                    children: [
                        {
                            href: '/monitor/detail',
                            name: '单品详情',
                            showCode: "monitor_detail",
                        },
                        {
                            href: '/monitor/competitive',
                            name: '竞品监控',
                            showCode: "monitor_competitive",
                        },
                        // {
                        //     href: '/monitor/price',
                        //     name: '价格监控',
                        //     showCode: "monitor_price",
                        // },
                        {
                            href: "/monitor/annual-fee",
                            name: "开通竞品监控",
                            showCode: "monitor_annual-fee",
                        },
                        {
                            href: '/monitor/record',
                            name: '历史记录',
                            showCode: "monitor_record",
                        },
                    ]
                },
                {
                    href: '/giftShop',
                    icon: 'el-icon-s-platform',
                    name: '礼品发货',
                    children: [
                        {
                            href: '/store/list',
                            name: '商品列表',
                            showCode: "store_list",
                        },
                        {
                            href: '/store/orderList',
                            name: '订单列表',
                            showCode: "store_orderList",
                        },
                        {
                            href: '/store/package',
                            name: '包裹列表',
                            showCode: "store_package",
                        }
                    ]
                },
                {
                    href: '/flow',
                    icon: 'el-icon-s-marketing',
                    name: '流量中心',
                    children: [
                        {
                            href: '/flow/taobao',
                            name: '淘宝流量提升',
                            showCode: "flow_taobao",
                        },
                        {
                            href: '/flow/pdd',
                            name: '拼多多流量提升',
                            showCode: "flow_pdd",
                        },
                        {
                            href: '/flow/jd',
                            name: '京东流量提升',
                            showCode: "flow_jd",
                        },
                        {
                            href: '/flow/dy',
                            name: '抖音流量提升',
                            showCode: "flow_dy",
                        },
                        {
                            href: '/flow/tasklist',
                            name: '任务列表',
                            showCode: "flow_taskList",
                        }
                    ]
                },
                {
                    href: '/user',
                    icon: 'el-icon-user-solid',
                    name: '用户中心',
                    children: [
                        {
                            href: '/personCenter',
                            name: '账户中心',
                            showCode: "personCenter",
                        },
                        {
                            href: '/newsCenter',
                            name: '消息中心',
                            showCode: "newsCenter",
                        },
                        {
                            href: '/vip',
                            name: '开通会员',
                            showCode: "vip",
                        },
                        {
                            href: '/promotion-manage',
                            name: '推广管理',
                            showCode: "promotion_manage"
                        },
                        {
                            href: '/busQuery',
                            name: '业务查询',
                            showCode: "busQuery",
                        },
                        {
                            href: '/recharge',
                            name: '充值记录',
                            showCode: "recharge",
                        },
                        // {
                        //     href: '/storeManage',
                        //     name: '店铺管理',
                        //     showCode: "storeManage",
                        // }
                    ]
                }
            ],
            showSidebarRouter: ["/head_screen/micro-details", "/head_screen/ask-everyone", "/head_screen/buy-ask-everyone", "/head_screen/first-order-discount", "/head_screen/farm", "/head_screen/wangwang", "/head_screen/other-shop", "/head_screen/other-fee", "/head_screen/black-box", "/head_screen/buyers-show", "/head_screen/daily-deals", "/head_screen/poly-cost-effective", "/head_screen/buy-page", "/head_screen/coins", "/head_screen/firstPause", "/head_screen/new-products", "/first-flow-dy/first-flow", "/first-flow-dy/guess", "/first-flow-pdd/said-ok", "/first-flow-pdd/first-flow"], // 显示侧边栏的路由
            noSidebarShow: [
                "/tbmobile/iFashion",
                "/head_screen/life",
                "/head_screen/coins",
                "/tbmobile/globalshop",
                "/tbmobile/hbooth",
                "/head_screen/buy-page",
                "/head_screen/poly-cost-effective",
                "/head_screen/daily-deals",
                "/head_screen/buyers-show",
                "/head_screen/firstPause",
                "/head_screen/new-products",
                "/head_screen/black-box",
                "/head_screen/other-fee",
                "/head_screen/other-shop",
                "/head_screen/wangwang",
                "/head_screen/farm",
                "/head_screen/first-order-discount",
                "/head_screen/buy-ask-everyone",
                "/tbmobile/probaby",
                "/head_screen/ask-everyone",
                // 20240307
                "/tbmobile/tm-live",
                "/tbmobile/brand-undergarments",
                "/tbmobile/tnei-discover",
                "/tbmobile/tnei-recommandations",
                "/tbmobile/tmr-topic-cloud",
                "/tbmobile/tm-must-list",
                "/tbmobile/details-main-img",
                "/tbmobile/short-video-fullpage",
                "/tbmobile/tm-nice-price",
                "/tbmobile/tm-plt",
                "/tbmobile/tm-nice-goodies",
                "/tbmobile/tiny-black-box",
                "/tbmobile/guess-your-like-star",
                "/tbmobile/first-order-coupon",
                "/tbmobile/taobao-foodie",
                "/tbmobile/trend-play",
                "/tbmobile/holiday-guide",
                "/tbmobile/piece-house",
                "/tbmobile/my-cute-pets",
                "/tbmobile/cat-fresh-food",
                "/tbmobile/trend-woo",
                "/tbmobile/cool-planet",
                "/tbmobile/odp",
                "/tbmobile/global-purchase",
                "/tbmobile/tm-neighbor-goodies",
                "/tbmobile/tmall-international",
                "/tbmobile/tmall-luxury",
                "/tbmobile/tmall-apple-fans",
                "/tbmobile/tmall-list",
                "/tbmobile/tmall-creation",

                "/head_screen/everyday-low-price",
                "/head_screen/post-purchase-recommandations",
                "/head_screen/tb-good-price",
                "/head_screen/tnei-unclassified",
                "/head_screen/tnei-guess-like",
                "/head_screen/billions-subsidies",
                "/head_screen/car-life",
                "/head_screen/favorable-life",
                "/head_screen/domestic-electric-appliance",
                "/head_screen/trend-electric-digital",
                "/head_screen/deep-in-autumn",
                "/head_screen/tend-fun-festival",
                "/head_screen/fun-season",
                "/head_screen/dopamine",
                "/head_screen/pet-day",
                "/head_screen/ice-festival",
                "/head_screen/valentine-day",
                "/head_screen/hot-sale-shoes-boots",
                "/head_screen/live-dress",
                "/head_screen/spring-new",
                "/head_screen/global-trend",
                "/head_screen/global-discover",
                "/head_screen/international-self-support",
                "/head_screen/home-appliance",
                "/head_screen/home-improvement-goodies",
                "/head_screen/home-improvement-nice-price",
                "/head_screen/home-must-have",
                "/head_screen/home-enjoy-life",
                "/head_screen/fashion",
                "/head_screen/boutique-furniture",
                "/head_screen/quality-applicance",
                "/head_screen/sport-trend",
                "/head_screen/sport-goodies",
                "/head_screen/enjoy-nice-food",
                "/head_screen/beauty-toiletry",
                "/head_screen/mother-baby",
                "/head_screen/culture",
                "/head_screen/corporate-procurement",
                "/head_screen/corporate-culture",
                "/head_screen/publish-trend",
                "/head_screen/supper-item",
                "/head_screen/jewelry",
                "/head_screen/brand-ceremony",
                "/head_screen/woman-fashion",
                "/head_screen/subtlety-pet",
                "/head_screen/watch-eyeglasses",
                "/head_screen/health-life",
                "/head_screen/kitchenette",
                "/head_screen/pre-enjoy-trend",
                "/head_screen/bedding-home-decor",
                "/head_screen/spring-newest",
                "/head_screen/tmall-u-first",
                "/head_screen/tmall-market",
                "/head_screen/tmall-medicine",
                "/head_screen/brand-digital",
                "/head_screen/brand-jewelry",
                "/head_screen/brand-mother-baby",
                "/head_screen/brand-home-cleaner",
                "/head_screen/brand-personal-care",
                "/head_screen/brand-beauty",
                "/head_screen/brand-man-clothes",
                "/head_screen/brand-undergarments",
            ], // 不展示的路由
        }
    },
    watch: {
        $route(newValue, oldValue) {
            let activeFirstId;
            for (const firstMenu in this.basicMenu) {
                for (const key in this.basicMenu[firstMenu].children) {
                    // 如果路由在菜单内, 或者在白名单内, 则显示侧边栏
                    if (this.showSidebarRouter.includes(newValue.path) || newValue.path.includes(this.basicMenu[firstMenu].children[key].url) || this.basicMenu[firstMenu].children[key].url === newValue.meta.activeMenu) {
                        activeFirstId = firstMenu;
                    }
                }
            }
            this.$store.dispatch("setSiderBarCate", Number(activeFirstId));
        },
    },
    methods: {
        apiIndex(cItem) {
            let baseUrl = "http://doc.vv-tool.com";

            // 分站的文档地址
            if (!this.isMainSite && this.configData.proxy_info && this.configData.proxy_info.site_doc_host) {
                if (this.configData.proxy_info.site_doc_host.indexOf("//") > -1) {
                    baseUrl = this.configData.proxy_info.site_doc_host;
                } else {
                    baseUrl = `//${this.configData.proxy_info.site_doc_host}`;
                }
            }

            return `${baseUrl}${cItem.url}`
        },

        menuItemClick(url) {
            if (url.includes("//")) {
                window.open(url)
            } else {
                this.$router.push(url);
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.sider-wrap {
    width: 1315px;
    height: 1px;
    position: relative;
    margin: 0 auto;
}

@media only screen and (min-width: 1500px) {
    .sider-wrap {
        width: 1315px;
    }
}

.siderBar {
    position: absolute;
    top: 20px;
    width: 150px;
    left: 10px;
    border-radius: 10px;
    overflow: hidden;

    .el-submenu .el-menu-item {
        // padding: 0;
        min-width: auto;
        background: #a4a4a4 !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 10px;
        padding-left: 10px !important;
    }

    .el-menu-item.is-active {
        // color: #c70026 !important;
        // border-left: 3px solid #c70026;
        border-left: none;
        background: #399dfe !important;
        // i {
        //     color: #c70026 !important;
        // }
    }

    .el-menu-item:focus,
    .el-menu-item:hover {
        background-color: #62b0fe !important;
    }

    .el-menu-item {
        color: #fff !important;
    }

    .el-submenu__title i {
        color: #909399;
    }
}


</style>

<style lang="scss">
.siderBar {
    .el-submenu .el-menu-item {
        height: 36px;
        line-height: 36px;
        font-size: 14px;
    }

    .el-submenu__title i {
        color: #fff;
    }

    .el-submenu .el-menu {
        background: #a4a4a4 !important;
    }

    .el-submenu__title:focus,
    .el-submenu__title:hover {
        background-color: #62b0fe !important;
    }
}
</style>
